import { Link } from "react-router-dom";

const Home = () => {
  return (
    <section className="full-height d-flex flex-column justify-content-between">
      <div className="container d-flex justify-content-center align-items-center mt-5 pt-5 pt-md-0">
        <img src="/images/logo/koreni_logo.webp" alt="about-us" className="img-fluid image" />
      </div>
      <div className="bg-black text-center py-2 pt-md-4">
        <div className="container">
          <div className="row ps-5 ps-md-0">
            <div className="col-md-4 d-flex align-items-center">
              <i className="bi bi-envelope icon-footer me-2"></i>
              <p className="mb-0">
                <a className="text-decoration-none text-white ms-2" href="mailto:nemanja@koreni1934.rs">nemanja@koreni1934.rs</a>
              </p>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-md-center py-3 py-md-0">
              <i className="bi bi-telephone icon-footer me-2"></i>
              <p className="mb-0">
                <a className="text-decoration-none text-white ms-2" href="tel:+381649500960">+381 64 95 00 960</a>
              </p>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-md-end">
              <i className="bi bi-geo-alt icon-footer me-2"></i>
              <p className="mb-0 text-white ms-2">Lipovac bb</p>
            </div>
          </div>
          <div className="text-center bt-white mt-2 pt-1">
            <Link to="/privacy-policy" className="me-4 text-decoration-none text-white">Politika privatnosti</Link>
            <Link to="/cookie-policy" className="ms-4 text-decoration-none text-white">Kolačići</Link>
          </div>
        </div>
      </div>

    </section>
  )
}
export default Home;


